import React from 'react';
import GoogleMaps from '../components/GoogleMaps';
import IconGoogleMaps from '../assets/images/icons/google_map_icon.svg';

import { Col } from 'reactstrap';

const linkUbicacion =
  'https://www.google.com/maps/place/Pro+Odontolog%C3%ADa/@-31.4216389,-64.1871112,19z/data=!3m1!4b1!4m5!3m4!1s0x9432a39ab09b0fed:0xb1d73bb072b24d0!8m2!3d-31.421635!4d-64.1865793?hl=es';

export const Mapa = () => {
  return (
    <div className='section section-responsive section-verde-claro'>
      <Col>
        <h3 className='text-azul font-weight-semi text-center  section-subtitle order-1 '>
          Nos encontrás en:
        </h3>
      </Col>
      <Col>
        <div className='d-flex justify-content-center'>
          <a href={linkUbicacion}>
            <img alt='' src={IconGoogleMaps} />
          </a>
          <h4 className='pb-4 font-weight-normal text-center section-body order-2'>
            Independencia 468, Nueva Córdoba.
          </h4>
        </div>
      </Col>
      <Col className='px-0'>
        <GoogleMaps />
      </Col>
    </div>
  );
};
