import React from 'react';
import { Container } from 'reactstrap';
import BannerCarousel from '../components/BannerCarousel';
import MultiColorLine from '../components/MultiColorLine';
const Banner = () => {
  return (
    <Container style={{ paddingTop: 66.5 }} className='px-0' fluid>
      <BannerCarousel />

      <MultiColorLine />
    </Container>
  );
};

export default Banner;
