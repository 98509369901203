import React from 'react';

const topWavesProps = {
  style: { marginBottom: '-1px' },
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 1440 320',
};
const topWaves = [
  {
    fill: '#AEDEDA',
    fillOpacity: '1',
    d: 'M0,256L80,250.7C160,245,320,235,480,197.3C640,160,800,96,960,64C1120,32,1280,32,1360,32L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z',
  },
  {
    fill: '#89D0C9',
    fillOpacity: '1',
    d: 'M0,0L80,0C160,0,320,0,480,53.3C640,107,800,213,960,250.7C1120,288,1280,256,1360,240L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z',
  },
];
const bottomWavesProps = {
  style: { marginBottom: '-1px' },
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 1440 320',
};
const bottomWaves = [
  {
    d: 'M1440 64L1380 90.7C1320 117 1200 171 1080 186.7C960 203 840 181 720 154.7C600 128 480 96 360 69.3C240 43 120 21 60 10.7L0 0H60C120 0 240 0 360 0C480 0 600 0 720 0C840 0 960 0 1080 0C1200 0 1320 0 1380 0H1440V64Z',
    fill: '#AEDEDA',
  },
  {
    fill: '#89D0C9',
    fillOpacity: '1',
    d: 'M0,64L60,90.7C120,117,240,171,360,186.7C480,203,600,181,720,154.7C840,128,960,96,1080,69.3C1200,43,1320,21,1380,10.7L1440,0L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z',
  },
];

const FondoOlasVerdes = (props) => {
  return (
    <>
      <svg {...topWavesProps}>
        {topWaves.map((wave) => (
          <path {...wave} />
        ))}
      </svg>
      <div className='section section-responsive section-verde-oscuro'>{props.children}</div>
      <svg {...bottomWavesProps}>
        {bottomWaves.map((wave) => (
          <path {...wave} />
        ))}
      </svg>
    </>
  );
};

export default FondoOlasVerdes;
