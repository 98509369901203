import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Unimed from '../assets/images/obrasSociales/Unimed.png';
import FederadaSalud from '../assets/images/obrasSociales/Federada_salud.png';
import CPCE from '../assets/images/obrasSociales/CPCE.png';
import Daspu from '../assets/images/obrasSociales/Daspu.png';
import Galeno from '../assets/images/obrasSociales/Galeno.png';
import Jerarquicos from '../assets/images/obrasSociales/Jerarquicos.png';
import PrevencionSalud from '../assets/images/obrasSociales/Prevencion_salud.png';
import Apross from '../assets/images/obrasSociales/Apross.png';
import Integral from '../assets/images/obrasSociales/Integral.png';
import IOSFA from '../assets/images/obrasSociales/IOSFA.png';
import { Link } from 'react-scroll';

const ObrasSociales = () => {
  return (
    <Container>
      <Row className='justify-content-center p-3 m-4'>
        <h1 className='text-azul font-weight-normal section-title p-1 m-5'>ATENDEMOS</h1>
      </Row>

      <Row className='justify-content-center my-4 p-auto'>
        <Col className='p-5' xs='auto' lg='4'>
          <img
            alt='federada salud'
            className='img-responsive img-fluid p-2 m-2'
            src={FederadaSalud}
          />
        </Col>
        <Col className='p-5' xs='auto' lg='4'>
          <img alt='daspu' className=' img-fluid img-rounded p-4' src={Daspu} />
        </Col>
        <Col className='p-5' xs='auto' lg='4'>
          <img alt='jerarquicos' className=' img-fluid p-2 m-2' src={Jerarquicos} />
        </Col>
      </Row>

      <Row className='justify-content-center  my-4 p-auto'>
        <Col className='p-5' xs='auto' lg='4'>
          <img alt='unimed' className=' img-fluid p-2 m-2' src={Unimed} />
        </Col>
        <Col className='px-2 py-5' xs='auto' lg='4'>
          <img
            alt='prevencion salud'
            className='img-responsive img-fluid p-4'
            src={PrevencionSalud}
          />
        </Col>
        <Col className='p-5' xs='auto' lg='4'>
          <img alt='CPCE' className=' img-fluid p-2 m-2' src={CPCE} />
        </Col>
      </Row>

      <Row className='justify-content-center my-4 p-auto'>
        <Col className='p-5' xs='auto'>
          <img alt='galeno' className=' img-fluid p-2' src={Galeno} />
        </Col>
        <Col className='p-1' xs='auto'>
          <img alt='integral' className=' img-fluid p-2' src={Integral} />
        </Col>
        <Col className='p-2' xs='auto'>
          <img alt='IOSFA' className=' img-fluid p-2' src={IOSFA} />
        </Col>
      </Row>

      <Row className='justify-content-center my-4 p-auto'>
        <Col className='p-3' xs='auto'>
          <img
            href='http://www.apross.gov.ar/'
            alt='apross'
            className=' img-fluid p-2'
            src={Apross}
          />
        </Col>
      </Row>

      <Row className='justify-content-center p-0 m-0'>
        <Link
          className='text-salmon-fuerte font-weight-normal section-subtitle p-1 m-3'
          to='consultanos'
          activeClass='active'
          spy={true}
          smooth={true}
          offset={-70}
          duration={700}>
          ¡Consultanos por la tuya!
        </Link>
      </Row>
    </Container>
  );
};

export default ObrasSociales;
