import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import '../assets/override/cards-queries.css';

const Doctoraliar = () => {
  return (
    <Container className='mt-5' fluid='sm'>
      <Row className='justify-content-center'>
        <Col>
          <iframe
            title='flor_profile'
            frameborder='0'
            scrolling='no'
            allowtransparency='true'
            data-id='rn5poqnfbe'
            src='https://widgets.doctoraliar.com/doctor/widget/big/florencia-riccardo?customUtm=null&amp;id=rn5poqnfbe&amp;header=null&amp;content=null&amp;fullwidth=null&amp;referer=http%3A%2F%2Flocalhost%3A3000%2F&amp;hide_branding=true&amp;widget_position=bottom&amp;opinion=true'
            style={{ border: 'none', overflow: 'hidden', width: '100%', height: '656px' }}></iframe>
        </Col>
      </Row>
    </Container>
  );
};

export default Doctoraliar;
