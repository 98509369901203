import ConsultanosForm from '../components/ConsultanosForm';
import React from 'react';
import { Card, CardBody, CardTitle, Container } from 'reactstrap';
import FondoConsultanos from '../assets/images/Consultanos/FondoConsultanos.webp';

const Consultanos = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${FondoConsultanos})`,
          height: '920px',
          backgroundRepeat: 'no-repeat',
        }}
        className='d-flex align-items-center justify-content-center'>
        <Container fluid='sm'>
          <Card className='card-plain bg-white' style={{ borderRadius: '15px', boxShadow: '0px 0px 8px #FF8C69' }}>
            <CardBody>
              <blockquote className='blockquote'>
                <CardTitle className='text-salmon-fuerte font-weight-semi text-center section-subtitle' tag='h2'>
                  CONSULTANOS
                </CardTitle>
              </blockquote>
              <Container style={{ paddingTop: '5px' }}>
                <ConsultanosForm />
              </Container>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default Consultanos;
