import React from 'react';
import { Container, Card, CardBody, CardFooter, Row, Col, CardTitle } from 'reactstrap';
import FlorProfilePic from '../assets/images/profile/flor_profile.webp';
import MacaProfilePic from '../assets/images/profile/maca_profile.webp';
import '../assets/override/cards-queries.css';

const Nosotras = () => {
  return (
    <Container>
      <Card className='card-profile card-plain '>
        <CardTitle>
          <h1 className='text-salmon-fuerte font-weight-normal section-title my-2 pt-5'>NOSOTRAS</h1>
        </CardTitle>
        <CardBody>
          <Row sm='auto'>
            <Col>
              <img
                alt='foto perfil Macarena'
                style={{ width: '130px', height: '130px' }}
                className='img-circle'
                src={MacaProfilePic}></img>
              <p className='text-azul font-weight-normal h4'>
                Macarena López
                <br />
                <br />
                M.P. 11272
              </p>
            </Col>
            <Col>
              <img
                alt='foto perfil Florencia'
                style={{ width: '130px', height: '130px' }}
                className='img-circle'
                src={FlorProfilePic}></img>
              <p className='text-azul  font-weight-normal h4 '>
                Florencia Riccardo
                <br />
                <br />
                M.P. 11031
              </p>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col xs='11' sm='10' md='9' lg='9' xl='10'>
              <p className='h3 text-justify font-weight-normal section-body'>
                En <b className='text-salmon-fuerte font-weight-normal'>PRO ODONTOLOGÍA</b> somos un equipo de
                profesionales de la salud capacitadas para ayudar a conservar la salud bucal de nuestros pacientes
                dedicando nuestro trabajo a la prevención, diagnóstico y tratamiento de las diferentes enfermedades
                bucodentales.
                <br />
                <br /> Nuestro consultorio cuenta con excelentes medidas de bioseguridad y de equipamiento para que tu
                experiencia en la visita odontológica sea la mejor. <br />
                <br />
                Tenemos un principal objetivo y es que nuestros pacientes encuentren un espacio de comodidad en donde
                tengan confianza en nuestra atención y puedan llegar a tener o mantener la sonrisa que se merecen.
              </p>
              <div style={{ visibility: 'hidden' }}>
                <span>dentista</span>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <h3 className='p-5 text-salmon-fuerte font-weight-normal section-subtitle'>Buscanos en las redes</h3>
          <Row className=' justify-content-center'>
            <Col xs='3' sm='4' md='auto'>
              <a href='https://www.facebook.com/proodontologia.cba/' className='btn-just-icon btn btn-facebook btn-lg'>
                <i className='fa fa-facebook'></i>
              </a>
            </Col>
            <Col xs='3' sm='4' md='auto'>
              <a
                href='https://api.whatsapp.com/send?phone=543516609221'
                className='btn-just-icon btn btn-whatsapp btn-lg'>
                <i className='fa fa-whatsapp'></i>
              </a>
            </Col>
            <Col xs='3' sm='4' md='auto'>
              <a href='https://www.instagram.com/pro.odontologia/' className='btn-just-icon btn btn-instagram btn-lg'>
                <i className='fa fa-instagram'></i>
              </a>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Container>
  );
};

export default Nosotras;
