import React from 'react';
import { Col, Row, Container, Media,UncontrolledTooltip } from 'reactstrap';
import Facebook from '../assets/images/icons/footer/facebook.svg';
import Instagram from '../assets/images/icons/footer/instagram.svg';
import Whatsapp from '../assets/images/icons/footer/whatsapp.svg';
import Gmail from '../assets/images/icons/footer/gmail.svg';

const Footer = () => {
  const SocialMediaInfo = [
    {
      link: 'https://www.facebook.com/proodontologia.cba/',
      src: Facebook,
      alt: 'facebook',
      text: 'Pro Odontologia',
    },
    {
      link: 'https://www.instagram.com/pro.odontologia/',
      src: Instagram,
      alt: 'instagram',
      text: 'pro.odontologia',
    },
  ];

  const ContactInfo = [
    {
      link: 'mailto:proodontologia.cba@gmail.com',
      src: Gmail,
      alt: 'gmail',
      text: 'proodontologia.cba@gmail.com',
    },
    {
      link: 'https://api.whatsapp.com/send?phone=543516609221',
      src: Whatsapp,
      alt: 'whatsapp',
      text: '+54 9 3516 60-9221',
    },
  ];

  const footerInfo = [ContactInfo, SocialMediaInfo];

  return (
    <Container className='bg-azul footer' fluid>
      <Row className='justify-content-center'>
        {footerInfo.map((elem) => (
          <Col xs='auto' md='3' lg='5' xl='4'>
            <Row xs='2' lg='1' className='justify-content-center'>
              {elem.map((subElem) => (
                <Col key={subElem.alt}>
                  <Media>
                    <Media left href={subElem.link}>
                      <Media object src={subElem.src} alt={subElem.alt} />
                    </Media>
                    <Media body>
                      <a
                        id={subElem.alt}
                        className='mt-3 p-0 ml-0 btn-link btn text-white font-weight-normal d-none d-lg-block h5'
                        href={subElem.link}>
                        {subElem.text}
                      </a>
                    </Media>
                  </Media>
                </Col>
              ))}
            </Row>
          </Col>
        ))}
      </Row>
      <Col>
        <hr className='p-0 mb-0 mt-0' />
        <div>
       
          <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ' id="developers">
            <span className='copyright text-white'> © 2021 Desarrollado por Del'Ricc.</span>
          </a>
          <UncontrolledTooltip placement="top" target="developers" delay={0}>
                  <h6 className="text-warning">soathekid<b className="text-success">@</b><b className="text-danger">gmail.com</b></h6>
         </UncontrolledTooltip>  
        </div>
      </Col>
    </Container>
  );
};

export default Footer;
