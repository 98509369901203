import React from 'react';
import ReactDOM from 'react-dom';
//import { getLCP } from 'web-vitals';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/scss/bootstrap.scss';
import 'assets/scss/paper-kit.scss?v=1.3.0';
import 'assets/demo/demo.css?v=1.3.0';
import Routing from './routes/Routing';

ReactDOM.render(
  <BrowserRouter>
    <Routing />
  </BrowserRouter>,
  document.getElementById('root')
);
//getLCP(console.log);
