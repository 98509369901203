import { React } from 'react';

let containerStyle = {
  width: `1920px`,
  height: `500px`,
};

const GoogleMaps = () => {
  return (
    <div className=' pb-5 pt-2 d-flex justify-content-center'>
      <iframe
        title='googlemaps'
        width={containerStyle.width}
        height={containerStyle.height}
        style={{ border: 0 }}
        loading='lazy'
        allowfullscreen
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.727416896232!2d-64.18875268424827!3d-31.421635403651315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432a39ab09b0fed%3A0xb1d73bb072b24d0!2sPro%20Odontolog%C3%ADa!5e0!3m2!1ses!2sus!4v1642189154425!5m2!1ses!2sus'></iframe>
    </div>
  );
};

export default GoogleMaps;
