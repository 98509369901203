import React, { useEffect, useState } from 'react';
import Headroom from 'headroom.js';
import LogoNavbar from '../assets/images/logos/LogoNavbar.png';
import LogoBioseguridad from '../assets/images/logos/LogoBioseguridad.png';
import LogoConsultorio from '../assets/images/logos/LogoConsultorio.png';
import LogoEspecialidades from '../assets/images/logos/LogoEspecialidades.png';
import LogoNosotras from '../assets/images/logos/LogoNosotras.png';
import LogoTips from '../assets/images/logos/LogoTips.png';
import { Link } from 'react-scroll';

// reactstrap components
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav } from 'reactstrap';
import { Container } from 'reactstrap';

// core components

function Topbar() {
  const [bodyClick, setBodyClick] = useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const closeSideBarOnScroll = () => {
    console.log(document.documentElement.classList.value);
    if (document.documentElement.classList.value === 'nav-open') {
      document.documentElement.classList.toggle('nav-open');
      setBodyClick(false);
      setCollapseOpen(false);
    }
  };
  window.addEventListener('scroll', closeSideBarOnScroll);

  useEffect(() => {
    let headroom = new Headroom(document.getElementById('topbar'), {
      offset: 35, // PIXELES PARA BAJAR.
      tolerance: 15, // PIXELES PARA SUBIR.
    });
    // initialise
    headroom.init();
  });

  return (
    <>
      {/* CUANDO HACE CLICK FUERA DEL SIDEBAR ABIERTO.*/}
      {bodyClick ? (
        <div
          id='bodyClick'
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setCollapseOpen(false);
            setBodyClick(!bodyClick);
          }}
        />
      ) : null}

      <Navbar color='verde-claro-topbar' expand='lg' id='topbar' className='fixed-top'>
        <Container>
          <div className='navbar-translate'>
            <NavbarBrand id='navbar-brand-icon' className='py-2' href='/'>
              <img className='icon-navbar' src={LogoNavbar} alt='Logo.' width='40' />
              PRO Odontología
            </NavbarBrand>

            <button
              className='navbar-toggler'
              id='navbarNav'
              type='button'
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setBodyClick(true);
                setCollapseOpen(true);
              }}>
              <span className='navbar-toggler-bar bar1' />
              <span className='navbar-toggler-bar bar2' />
              <span className='navbar-toggler-bar bar3' />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className='ml-auto' navbar>
              <NavItem className='active'>
                <NavLink>
                  <Link
                    className='text-azul font-weight-bold'
                    activeClass='active'
                    to='nosotras'
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}>
                    <img
                      className='icon-navbar img-fluid'
                      src={LogoNosotras}
                      alt='Logo.'
                      width='40'
                    />
                    Nosotras
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link
                    className='text-azul font-weight-bold'
                    activeClass='active'
                    to='especialidades'
                    spy={true}
                    smooth={true}
                    offset={20}
                    duration={500}>
                    <img
                      className='icon-navbar img-fluid'
                      src={LogoEspecialidades}
                      alt='Logo.'
                      width='40'
                    />
                    Especialidades
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link
                    className='text-azul font-weight-bold'
                    activeClass='active'
                    to='bioseguridad'
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}>
                    <img
                      className='icon-navbar img-fluid'
                      src={LogoBioseguridad}
                      alt='Logo.'
                      width='40'
                    />
                    Bioseguridad
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link
                    className='text-azul font-weight-bold'
                    activeClass='active'
                    to='consultorio'
                    spy={true}
                    smooth={true}
                    offset={100}
                    duration={500}>
                    <img
                      className='icon-navbar img-fluid'
                      src={LogoConsultorio}
                      alt='Logo.'
                      width='40'
                    />
                    Consultorio
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <a className='text-azul font-weight-bold' activeClass='active' href='/tips'>
                    <img className='icon-navbar img-fluid' src={LogoTips} alt='Logo.' width='40' />
                    Tips
                  </a>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Topbar;
