import EspecialidadesCarousel from 'components/EspecialidadesCarousel';
import React from 'react';
import { Container } from 'reactstrap';
import '../assets/override/titulo-section-queries.css';

const wavesTop = [
  {
    fill: '#feced0',
    fillOpacity: '1',
    d: 'M0,192L48,170.7C96,149,192,107,288,117.3C384,128,480,192,576,213.3C672,235,768,213,864,218.7C960,224,1056,256,1152,256C1248,256,1344,224,1392,208L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z',
  },
  {
    fill: '#feced0',
    fillOpacity: '0.7',
    d: 'M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z',
  },
];

const wavesBottom = [
  {
    fill: '#feced0',
    fillOpacity: '1',
    d: 'M0,160L48,149.3C96,139,192,117,288,128C384,139,480,181,576,170.7C672,160,768,96,864,69.3C960,43,1056,53,1152,58.7C1248,64,1344,64,1392,64L1440,64L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z',
  },
  {
    fill: '#feced0',
    fillOpacity: '0.7',
    d: 'M0,32L48,58.7C96,85,192,139,288,138.7C384,139,480,85,576,74.7C672,64,768,96,864,90.7C960,85,1056,43,1152,42.7C1248,43,1344,85,1392,106.7L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z',
  },
];

const wavePropsTop = {
  viewBox: '0 -10 1440 320',
  style: { marginBottom: '-40px' },
};

const wavePropsBottom = {
  viewBox: '0 -10 1440 320',
  style: { marginTop: '-14px' },
};

const Especialidades = () => {
  return (
    <div>
      <svg {...wavePropsTop}>
        {wavesTop.map((wave) => (
          <path {...wave} />
        ))}
      </svg>
      <Container fluid={true} className='px-0 bg-salmon-claro'>
        <h1
          className='text-azul font-weight-semi text-center section-title'
          style={{ marginBottom: 20 }}>
          ESPECIALIDADES
        </h1>

        <EspecialidadesCarousel />
      </Container>
      <svg {...wavePropsBottom}>
        {wavesBottom.map((wave) => (
          <path {...wave} />
        ))}
      </svg>
    </div>
  );
};

export default Especialidades;
