import React from 'react';

import FondoOlasVerdes from '../components/FondoOlasVerdes';
import ConsultorioCarousel from '../components/ConsultorioCarousel';
import { Row, Col, Container } from 'reactstrap';


const Consultorio = () => {
  return (
    <>
      <FondoOlasVerdes>
        <Container fluid >
          <h1 className='text-azul font-weight-normal text-center section-title p-0 mt-0 mb-5 '>
            NUESTRO CONSULTORIO
          </h1>
          <Row xs='1' className='justify-content-center'>
            <Col className='px-0'>
              <ConsultorioCarousel />
            </Col>
          </Row>
        </Container>
      </FondoOlasVerdes>
    </>
  );
};

export default Consultorio;
