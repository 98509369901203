import React from 'react';

import Carousel, { consts } from 'react-elastic-carousel';
import Extraccion from '../assets/images/Especialidades/Extraccion.webp';
import Limpieza from '../assets/images/Especialidades/Limpieza.webp';
import ArreglaCaries from '../assets/images/Especialidades/ArreglaCaries.webp';
import Blanqueamiento from '../assets/images/Especialidades/Blanqueamiento.webp';
import Conducto from '../assets/images/Especialidades/Conducto.webp';
import Implante from '../assets/images/Especialidades/Implante.webp';
import Odontopediatria from '../assets/images/Especialidades/Odontopediatria.webp';
import Ortodoncia from '../assets/images/Especialidades/Ortodoncia.webp';
import PlacaRelajacion from '../assets/images/Especialidades/PlacaRelajacion.webp';
import Protesis from '../assets/images/Especialidades/Protesis.webp';
import ItemEspecialidadesCarousel from './ItemEspecialidadesCarousel';
import '../assets/override/react-elastic-carousel.css';
import { Button } from 'reactstrap';

const items = [
  {
    img: Extraccion,
    title: 'Extracciones',
    body: ' ',
  },
  {
    img: Limpieza,
    title: 'Limpieza',
    body: '',
  },
  {
    img: ArreglaCaries,
    title: 'Arreglo de Caries',
    body: '',
  },
  {
    img: Blanqueamiento,
    title: 'Blanqueamiento',
    body: '',
  },
  {
    img: Conducto,
    title: 'Tratamiento de Conducto',
    body: '',
  },
  {
    img: Implante,
    title: 'Implantes',
    body: '',
  },
  {
    img: Odontopediatria,
    title: 'Odontopediatría',
    body: '',
  },
  {
    img: Ortodoncia,
    title: 'Ortodoncia',
    body: '',
  },
  {
    img: PlacaRelajacion,
    title: 'Placa de Relajación',
    body: '',
  },
  {
    img: Protesis,
    title: 'Protesis',
    body: '',
  },
];

const EspecialidadesCarousel = (props) => {
  function myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? '🦷' : '🦷';
    return (
      <Button className='h2 muelita-font-size px-0' color='link' onClick={onClick}>
        {pointer}
      </Button>
    );
  }

  return (
    <Carousel
      breakPoints={[
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1, itemsToScroll: 1 },
        { width: 1100, itemsToShow: 2, itemsToScroll: 1 },
        { width: 1150, itemsToShow: 2, itemsToScroll: 1 },
        { width: 1450, itemsToShow: 3, itemsToScroll: 2 },
        { width: 1750, itemsToShow: 4, itemsToScroll: 2 },
      ]}
      renderArrow={myArrow}
      itemPadding={[10, 20]}
      pagination={false}>
      {items.map((item) => (
        <ItemEspecialidadesCarousel key={item.title} {...item} />
      ))}
    </Carousel>
  );
};

export default EspecialidadesCarousel;
