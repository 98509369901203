import React from 'react';
import { NavItem, NavLink, Nav, TabContent, TabPane, Container, Row, Col } from 'reactstrap';

const BioSeguridad = () => {
  const [hTabs, setHTabs] = React.useState('1');

  return (
    <Container className='pb-5 mb-5'>
      <Col xs='12' className='px-0'>
        <h1 className='font-weight-semi mt-0 mb-4 text-center text-verde-oscuro section-title'>
          BIO SEGURIDAD
        </h1>
        <div className='nav-tabs-navigation'>
          <div className='nav-tabs-wrapper'>
            <Nav id='tabs' className='justify-content-center' role='tablist' tabs>
              <NavItem>
                <NavLink
                  className={hTabs === '1' ? 'active' : ''}
                  onClick={() => {
                    setHTabs('1');
                  }}>
                  <h6 className=' font-weight-normal section-body'>PARA EL PACIENTE</h6>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={hTabs === '2' ? 'active' : ''}
                  onClick={() => {
                    setHTabs('2');
                  }}>
                  <h6 className=' font-weight-normal section-body'>PARA EL ODONTÓLOGO </h6>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={hTabs === '3' ? 'active' : ''}
                  onClick={() => {
                    setHTabs('3');
                  }}>
                  <h6 className=' font-weight-normal section-body'>ENTRE CADA PACIENTE</h6>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        <TabContent className='text-center' activeTab={'hTabs' + hTabs}>
          <TabPane tabId='hTabs1'>
            <Row className='justify-content-center '>
              <ul className=' text-left'>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>Cofia</p>
                </li>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>Bata</p>
                </li>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>Cubrezapatos</p>
                </li>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>
                    Buche con solución de agua oxigenada
                  </p>
                </li>
              </ul>
            </Row>
          </TabPane>
          <TabPane tabId='hTabs2'>
            <Row className='justify-content-center '>
              <ul className=' text-left'>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>Mamelucos desinfectados</p>
                </li>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>
                    Batas Únicas para cada paciente
                  </p>
                </li>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>Barbijo N95</p>
                </li>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>Pantalla protectora</p>
                </li>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>Guantes</p>
                </li>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>Cubrezapatos</p>
                </li>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>Cofia</p>
                </li>
              </ul>
            </Row>
          </TabPane>
          <TabPane tabId='hTabs3'>
            <Row className='justify-content-center '>
              <ul className=' text-left'>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>Limpieza</p>
                </li>
                <li className='h5 text-verde-oscuro'>
                  <p className='text-salmon-fuerte font-weight-normal'>
                    Alcohol al 75% en todas las superficies
                  </p>
                </li>
              </ul>
            </Row>
          </TabPane>
        </TabContent>
        
      </Col>
    </Container>
  );
};

export default BioSeguridad;
