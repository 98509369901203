import React from 'react';
import Muelita from '../assets/images/tips/muelita_proximamente.webp';
import { Col, Row, Container } from 'reactstrap';

const Tips = () => {
  return (
    <div style={{ backgroundColor: '#A1D2D9', paddingTop: 66.5 }}>
      <Container fluid>
        <Row xs={1} className='justify-content-center'>
          <Col className=''>
            <img
              style={{ width: '100%', height: '100%' }}
              className=' img-fluid'
              src={Muelita}
              alt='una muelita felz :)'
              caption='una muelita feliz :)'
            />
          </Col>
          <Col className='pt-5 pb-0'>
            <a href='https://www.freepik.es/vectores/medico'>
              <h5 className='display-5 text-center font-weight-normal text-white'>
                Vector de Muelitas creado por studiogstock - www.freepik.es
              </h5>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Tips;
