import React from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import { Button } from 'reactstrap';

import Consultorio1 from '../assets/images/consultorio/consultorio1.webp';
import Consultorio2 from '../assets/images/consultorio/consultorio2.webp';
import Consultorio3 from '../assets/images/consultorio/consultorio3.webp';
import Consultorio4 from '../assets/images/consultorio/consultorio4.webp';
import Consultorio5 from '../assets/images/consultorio/consultorio5.webp';
import Consultorio6 from '../assets/images/consultorio/consultorio6.webp';

const items = [
  {
    src: Consultorio1,
    altText: 'Consultorio1 1',
    caption: '',
  },
  {
    src: Consultorio2,
    altText: 'Consultorio1 2',
    caption: '',
  },
  {
    src: Consultorio3,
    altText: 'Consultorio1 3',
    caption: '',
  },
  {
    src: Consultorio4,
    altText: 'Consultorio1 4',
    caption: '',
  },
  {
    src: Consultorio5,
    altText: 'Consultorio1 5',
    caption: '',
  },
  {
    src: Consultorio6,
    altText: 'Consultorio1 6',
    caption: '',
  },
];

const ConsultorioCarousel = (props) => {
  function myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? '🦷' : '🦷';
    return (
      <Button className='h2 muelita-font-size px-0' color='link' onClick={onClick}>
        {pointer}
      </Button>
    );
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 850, itemsToShow: 1 },
    { width: 1150, itemsToShow: 1 },
    { width: 1450, itemsToShow: 2, itemsToScroll: 1 },
    { width: 1750, itemsToShow: 2, itemsToScroll: 1 },
  ];

  const slides = items.map((item) => {
    return (
      <div key={item.altText}>
        <img draggable='false' className='rounded img-fluid' alt={item.altText} src={item.src} />
      </div>
    );
  });

  return (
    <div>
      <Carousel
        pagination={false}
        focusOnSelect={true}
        breakPoints={breakPoints}
        renderArrow={myArrow}>
        {slides}
      </Carousel>
    </div>
  );
};

export default ConsultorioCarousel;
