import React from 'react';
import Especialidades from '../sections/Especialidades';
import Consultanos from 'sections/Consultanos';
import Banner from 'sections/Banner';
import ObrasSociales from 'sections/ObrasSociales';
import Nosotras from 'sections/Nosotras';
import Consultorio from 'sections/Consultorio';
import BioSeguridad from 'sections/BioSeguridad';
import { Mapa } from 'sections/Mapa';
import Doctoraliar from 'sections/Doctoraliar';

const App = () => {
  return (
    <div>
      
      <div id='banner'>
        <Banner />
      </div>
      <div id='nosotras'>
        <Nosotras />
      </div >
      <div id='doctoraliar'>
       <Doctoraliar/>
      </div>
      <div id='especialidades'>
        <Especialidades />
      </div>
      <div id='bioseguridad'>
        <BioSeguridad />
      </div>
      <div id='obrasSociales'>
        <ObrasSociales />
      </div>
      <div id='consultorio'>
        <Consultorio />
      </div>
      <div id='consultanos'>
        <Consultanos />
      </div>
      <div id='encontranos'>
        <Mapa />
      </div>
      
    </div>
  );
};

export default App;
