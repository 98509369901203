import App from 'pages/App';
import Tips from 'pages/Tips';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Topbar from './../parts/Topbar';
import Footer from './../parts/Footer';

const Routing = () => {
  return (
    <div id='content'>
      <Topbar />
      <ToastContainer />
      <Switch>
        <Route path='/tips' component={Tips} />
        <Route path='/' component={App} />
      </Switch>
      <Footer />
    </div>
  );
};

export default Routing;
