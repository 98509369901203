import React from 'react';
import Carousel from 'react-elastic-carousel';
import Image1 from '../assets/images/banner/Banner1-compressed.webp';
import Image2 from '../assets/images/banner/Banner2-compressed.webp';
import Image3 from '../assets/images/banner/Banner3-compressed.webp';

import Image1Mobile1 from '../assets/images/banner/Banner-mobile1.webp';
import Image1Mobile2 from '../assets/images/banner/Banner-mobile2.webp';
import Image1Mobile3 from '../assets/images/banner/Banner-mobile3.webp';

import { isMobile, isDesktop } from 'react-device-detect';

import '../assets/override/react-elastic-carousel.css';

const items = [
  {
    src: Image3,
    altText: 'banner 3',
    caption: '',
  },
  {
    src: Image2,
    altText: 'banner 2',
    caption: '',
  },
  {
    src: Image1,
    altText: 'banner 1',
    caption: '',
  },
];

const itemsMobile = [
  {
    src: Image1Mobile3,
    altText: 'banner 3',
    caption: '',
  },
  {
    src: Image1Mobile2,
    altText: 'banner 2',
    caption: '',
  },
  {
    src: Image1Mobile1,
    altText: 'banner 1',
    caption: '',
  },
];

const BannerCarousel = () => {
  const slidesMobile = itemsMobile.map((item) => {
    return (
      <img
        className='img-fluid'
        alt={item.altText}
        draggable={false}
        src={item.src}
        key={item.altText}
      />
    );
  });

  const slidesBrowser = items.map((item) => {
    return (
      <img
        className='img-fluid'
        alt={item.altText}
        draggable={false}
        src={item.src}
        key={item.altText}
      />
    );
  });

  const imgType = () => {
    if (isMobile) {
      return slidesMobile;
    } else {
      if (isDesktop) return slidesBrowser;
    }
  };

  return (
    <Carousel pagination={false} enableAutoPlay autoPlaySpeed={4000} showArrows={false}>
      {imgType()}
    </Carousel>
  );
};
export default BannerCarousel;
