import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Col, Label, Row, Button, FormText } from 'reactstrap';

import { AvForm, AvGroup, AvFeedback } from 'availity-reactstrap-validation';
import AvField from 'availity-reactstrap-validation/lib/AvField';

import emailjs from 'emailjs-com';

const ConsultanosForm = () => {
  const initialValues = {
    nombre: '',
    email: '',
    telefono: '',
    asunto: '',
    mensaje: '',
  };

  const onValidSubmit = (event, values) => {
    toast.info('Enviando consulta...');
    emailjs
      .send(
        'it.pro.odontologia',
        'consultas_web',
        {
          from_name: values.nombre,
          asunto: values.asunto,
          message: values.mensaje,
          from_email: values.email,
          telefono: values.telefono,
        },
        'user_7PiDlrLFH54PD6mctxFWc'
      )
      .then((response) => {
        toast.success('Consulta enviada!');
      })
      .catch((error) => {
        toast.error('No se pudo enviar la consulta.');
      });
  };

  const propsFields = {
    nombre: {
      id: 'nombre',
      autoComplete: 'off',
      name: 'nombre',
      type: 'text',
      placeholder: 'Su nombre...',
      validate: {
        required: {
          value: true,
          errorMessage: 'Debe ingresar su nombre obligatoriamente.',
        },
        pattern: {
          value: '^[A-Z a-z]+$',
          errorMessage: 'El nombre solo debe tener letras y/o espacios.',
        },
        minLength: {
          value: 3,
          errorMessage: 'El nombre debe contener al menos 3 letras.',
        },
        maxLength: {
          value: 20,
          errorMessage: 'El nombre debe conetener menos de 20 letras.',
        },
      },
    },
    email: {
      id: 'email',
      autoComplete: 'off',
      name: 'email',
      type: 'text',
      placeholder: 'Su email...',
      validate: {
        required: {
          value: true,
          errorMessage: 'Debe ingresar un email obligatoriamente.',
        },
        email: {
          value: true,
          errorMessage: 'Debe ingresar su email correctamente',
        },
        maxLength: {
          value: 50,
          errorMessage: 'El email debe conetener menos de 30 letras.',
        },
      },
    },
    telefono: {
      id: 'telefono',
      autoComplete: 'off',
      name: 'telefono',
      type: 'text',
      placeholder: 'Su teléfono...',
      validate: {
        tel: {
          value: true,
          errorMessage: 'Debe ingresar su teléfono correctamente (1111-111111)',
        },
      },
    },
    asunto: {
      id: 'asunto',
      autoComplete: 'off',
      name: 'asunto',
      type: 'text',
      placeholder: 'Asunto de la consulta...',
      validate: {
        required: {
          value: true,
          errorMessage: 'Debe ingresar un asunto obligatoriamente.',
        },
        maxLength: {
          value: 70,
          errorMessage: 'El asunto debe conetener menos de 70 letras.',
        },
      },
    },
    mensaje: {
      id: 'mensaje',
      autoComplete: 'off',
      name: 'mensaje',
      type: 'textarea',
      placeholder: '¿Qué te gustaria consultar?',
      style: { maxHeight: '12rem', height: '12rem' },
      validate: {
        required: {
          value: true,
          errorMessage: 'Debe ingresar un mensaje obligatoriamente.',
        },
        maxLength: {
          value: 255,
          errorMessage: 'El mensaje debe conetener menos de 255 letras.',
        },
      },
    },
  };

  return (
    <>
      <AvForm
        style={{ fontSize: '17px' }}
        onSubmit={(event, errors, values) => {
          //Por las dudas.
        }}
        onInvalidSubmit={(event, errors, values) => {
          toast.error('Campos invalidos...');
        }}
        onValidSubmit={onValidSubmit}
        model={initialValues}>
        <Row>
          <Col xs={12} sm={12} md={6} xl={6}>
            <AvGroup>
              <Label className='text-azul font-weight-semi'>Nombre:</Label>
              <AvField {...propsFields.nombre} />
              <AvFeedback>Debe ingresar un nombre obligatoriamente.</AvFeedback>
            </AvGroup>
          </Col>
          <Col xs={12} sm={12} md={6} xl={6}>
            <AvGroup>
              <Label className='text-azul font-weight-semi'>Email:</Label>
              <AvField {...propsFields.email} />
              <AvFeedback>Debe ingresar un email valido.</AvFeedback>
            </AvGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} xl={6}>
            <AvGroup>
              <Label className='text-azul font-weight-semi'>Teléfono:</Label>
              <AvField {...propsFields.telefono} />
            </AvGroup>
          </Col>
          <Col xs={12} sm={12} md={6} xl={6}>
            <AvGroup>
              <Label className='text-azul font-weight-semi'>Asunto:</Label>
              <AvField {...propsFields.asunto} />
              <AvFeedback>Debe ingresar un asunto obligatoriamente.</AvFeedback>
            </AvGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <AvGroup>
              <Label className='text-azul font-weight-semi'>Mensaje:</Label>
              <AvField {...propsFields.mensaje} autoFocus />
              <AvFeedback>Debe ingresar un mensaje obligatoriamente.</AvFeedback>
              <FormText className='text-muted' color='default'>
                Nosotros nunca compartiremos esta información
              </FormText>
              <br />
              <li className='list-inline-item'>Email:</li>
              <li className='list-inline-item text-azul font-weight-normal'>proodontologia.cba@gmail.com</li>
              <br />
              <li className='list-inline-item'>Tel:</li>
              <li className='list-inline-item text-azul font-weight-normal'> +54 9 3516 60-9221</li>
            </AvGroup>
          </Col>
        </Row>
        <div className='d-flex justify-content-end'>
          <Button className='btn-round' color='primary' type='submit'>
            Enviar
          </Button>
        </div>
      </AvForm>
    </>
  );
};

export default ConsultanosForm;
