import React from 'react';
import { Row, Col, Container } from 'reactstrap';

const MultiColorLine = () => {
  return (
    //&nbsp;  es para que tengan un relleno las Col
    <Container fluid>
      <Row>
        <Col className='bg-salmon-fuerte' xs='4'>
          &nbsp;
        </Col>
        <Col className='bg-salmon-claro' xs='3'></Col>
        <Col className='bg-verde-claro' xs='2'></Col>
        <Col className='bg-azul' xs='3'></Col>
      </Row>
    </Container>
  );
};

export default MultiColorLine;
