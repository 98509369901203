import React from 'react';
import '../assets/override/cards-queries.css';
import { Card, CardBody, CardImg, CardText, CardTitle } from 'reactstrap';

const ItemEspecialidadesCarousel = ({ img, title, body }) => {
  return (
    <Card className='text-center'>
      <CardImg width='100%' alt='...' draggable={false} src={img} top />
      <CardBody style={{ padding: 5 }}>
        <CardTitle className='text-azul font-weight-normal'>
          {title}
        </CardTitle>
        <CardText>{body}</CardText>
      </CardBody>
    </Card>
  );
};

export default ItemEspecialidadesCarousel;
